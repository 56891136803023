import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { SendError } from "../services/error-log-service";

export const validateJwtToken = () => {
    if (typeof window !== `undefined`) {
        if (getJwtToken() === null) {
            window.location = '/401'
            return false;
        }
    }
    return true;
}

const decodeToken = () => {
    if (typeof window === `undefined`) return null;
    const token = getJwtToken();
    if (token === null || token === "undefined") {
        window.location = '/401'
    }
    
    let  decoded = "";

    try {
        decoded = jwt_decode(token);
      } catch(error) {
          SendError(error); 
        }

    if (decoded === undefined || decoded === null) {
        window.location = '/401'
    }
    return decoded;
}

export const getJwtTokenIdentifier = () => {
    const nameidentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
    const decoded = decodeToken();

    if (decoded != null)
    {
        return parseInt(decoded[nameidentifier]);
    }
}

export const getJwtTokenRoles = () => {
    const roleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    const decoded = decodeToken();

    if (decoded != null)
    {
        var tokenRoles = decoded[roleKey];

        if (Array.isArray(tokenRoles))
            return tokenRoles;
    
        return [tokenRoles];
    }
    else
    {
        return [];
    }
}

export const getJwtToken = () => {
    const token = Cookies.get('token');
    if (token === undefined || token === null || token === "") {
        return null;
    }
    return token;
}

export const getRefreshToken = () => {
    const token = Cookies.get('refresh-token');
    if (token === undefined || token === null || token === "") {
        return null;
    }
    return token;
}

export const removeJwtTokens = (location = '/401') => {
    if (typeof window === `undefined`) return null;
    Cookies.remove('refresh-token');
    Cookies.remove('token', { path: '/' });
    window.location = location;
}

export const setJwtTokens = (token, refreshToken) => {
    Cookies.set('token', token, { expires: 30 / 1440, path: '/' }); // 30 mins
    Cookies.set('refresh-token', refreshToken, { expires: 30 / 1440, path: '/' });
}  

export const getCookie = (name) => {
    const cookie = Cookies.get(name);
    if (cookie === undefined || cookie === null || cookie === "") {
        return null;
    }
    return cookie;
}

export const setCookie = (name, value, expires, path = '/') => {
    Cookies.set(name, value, { expires: expires, path: path });
}  
