import axios from "axios";
import { setJwtTokens } from '../services/auth-service'

/**
   * @description Refresh token cookies
   * @returns {String} JwtToken and random generated refresh token
 */
export const Refresh = async (data) => {
    return await axios.post(`token/refresh/`, data).then(function (response) {
        setJwtTokens(response.data.token, response.data.refreshToken);
        return response;
    })
}