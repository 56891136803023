import React from "react"
import axios from 'axios';
import { getJwtTokenIdentifier, getRefreshToken, getJwtToken, removeJwtTokens } from './src/services/auth-service'
import { Refresh } from './src/services/token-service'
import { HubProvider } from "./src/contexts/hub-context"
import { SendError, SendErrorMessage } from './src/services/error-log-service';

axios.defaults.baseURL = process.env.GATSBY_OUTIBLE_API_URL;
axios.defaults.headers.post = { 'Access-Control-Allow-Origin': '*' }

window.onerror = function(message, source, lineno, colno) {  
    SendErrorMessage(`${message}, Source: ${source}, Line Number: ${lineno}, Column Number: ${colno}, url: ${window.location}`)
    return true;
};  

axios.interceptors.request.use(
    function (request) {
        const token = getJwtToken();

        if (token !== null) {
            request.headers['Authorization'] = 'Bearer ' + token;
        }
        return request;
    },
    async function (error) {
        await SendError(error);
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        await SendError(error);
        // api is offline  
        if (error.response === undefined && error.message === "Network Error") {
            error.response = { data: "Something went wrong, please try again."};
            return Promise.reject(error);
        }

        if (error.response.status === 404) {
            window.location = '/404/'
            return Promise.reject(error);
        }

        var isTokenUrl = (error?.config?.url && error.config.url.indexOf("token/refresh") !== -1);
        if (error.response.status === 401 && error.response.headers["token-expired"] === "true" && !isTokenUrl) {
          return tokenRefresh().then(() => {
                error.config.headers['Authorization'] = 'Bearer ' + getJwtToken();
                return axios.request(error.config); // repeat the original request
            });
        }
        else if (error.response.status === 401
            && window.location.pathname.toLowerCase() !== '/login') {
            removeJwtTokens();
            return Promise.reject(error);
        }

        if (error.response.status === 400) {
            return Promise.reject(error);
        }

        // any other api reponse
        error.response = "Something went wrong, please try again.";
        return Promise.reject(error);
    }
);


async function tokenRefresh() {
    const response = await Refresh({ userId: getJwtTokenIdentifier(), jwtToken: getJwtToken(), refreshToken: getRefreshToken() });
    if (response.status !== 200) return response; // failed to refresh so return original 401 response
}

export const wrapRootElement = ({ element }) => ( // wraps the root element with HubProvider from React Context
    <HubProvider>{element}</HubProvider>
)