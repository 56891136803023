import React, { useEffect, useState, } from "react"
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getJwtToken } from "../services/auth-service.js"
import { SendError } from "../services/error-log-service.js";

const defaultState = {
  hubConnection: {},
  connectedAccounts: []
}

const HubContext = React.createContext(defaultState);

const HubProvider = ({ children }) => {
  const hubUrl = process.env.GATSBY_OUTIBLE_HUB_URL;
  const [hubConnection, setHubConnection] = useState(null);
  const [connectedAccounts, setConnectedAccounts ] = useState([]);

  useEffect(() => {
    const token = getJwtToken();

    if (token === null)
      return;

    const newHubConnection = new HubConnectionBuilder()
        .withUrl(hubUrl + 'presence', {
        accessTokenFactory: () =>  token
      })
      .withAutomaticReconnect()
      .build();

    setHubConnection(newHubConnection);
  }, []);

  useEffect(() => {
    if (!hubConnection) return;

    hubConnection
    .start()
    .catch(ex => {SendError(ex); console.log('Hub connection failed: ', ex)});

    hubConnection.on('AccountConnected', userId => {
        if (!connectedAccounts.includes(userId))
            setConnectedAccounts([...connectedAccounts, userId]);
    });

    hubConnection.on('AccountDisconnected', userId => {
        let removedAccounts = [...connectedAccounts];
        var index = removedAccounts.indexOf(userId);

        if (index !== -1)
            removedAccounts.splice(index, 1);

        setConnectedAccounts(removedAccounts);
    });

    hubConnection.on('GetOnlineAccounts', userIds => {
        let newOnlineAccounts = userIds.filter(x => !connectedAccounts.includes(x));
        let newConnectedAccounts = [...connectedAccounts];
        newConnectedAccounts = newConnectedAccounts.concat(newOnlineAccounts);
        setConnectedAccounts(newConnectedAccounts);
    });   
  }, [hubConnection]);

  return (
      <HubContext.Provider value={{ hubConnection, connectedAccounts }}>
        {children}
      </HubContext.Provider>
  )
}

export default HubContext
export { HubProvider }