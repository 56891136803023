import axios from "axios"

export const SendErrorMessage = async (error) => {
  return await axios
    .post(`errorlog`, { message: error })
    .then(function () {
      console.log("Error sent to site admin")
    })
    .catch(function (error) {
        SendError(error); 
        console.log("We are having problems right now, please contact the website admin")
    })
}

export const SendError = async (error) => {
  if ((error?.config?.url && error.config.url.indexOf("errorlog") !== -1) || 
  (window?.location && window.location.toString().indexOf("/login") !== -1 && error?.response?.status === 401)) {
    return;
  }

  const sourceMessage = error?.source ? `, Source: ${error.source}` : "";
  const lineNumberMessage = error?.lineno ? `, Line Number: ${error.lineno}` : "";
  const columnNumberMessage = error?.colno ? `, Column Number: ${error.colno}` : "";
  let errorMessage = `message: ${error?.message}, name: ${error?.name}, pageUrl: ${window?.location}${sourceMessage}${lineNumberMessage}${columnNumberMessage}`
  
  if (error?.response) {
      const apiUrl = error.config.url.indexOf("http") !== -1 ? 
        error.config.url : 
        (error.config.baseURL + error.config.url);

    errorMessage += `, URL: ${apiUrl}, 
    Status: ${error.response?.status}`
    }
    
  const stackMessage = error?.stack ? `, stack: ${error.stack}` : "";
  errorMessage += stackMessage;
  return SendErrorMessage(errorMessage);
}
